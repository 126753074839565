@import '../mapButtons';
@import '../mapEntities';

.VesselsMap #areaMap,
.VesselsMap #contourMap,
.VesselsMap #eezMap,
.VesselsMap #iceMap,
.VesselsMap #ICESMap,
.VesselsMap #phytoplanktonMap,
.VesselsMap #salinityMap,
.VesselsMap #seabedMap,
.VesselsMap #seaTempMap,
.VesselsMap #surfaceTempMap,
.VesselsMap #leafletMap,
.VesselsMap #windy,
.VesselsMap #zooplanktonMap,
.VesselsMap .leaflet-container {
  height: 100%;
  width: 100%;
  margin: 0 auto;

  #logo {
    top: -555px;
  }

  #calendar,
  #mobile-calendar {
    display: none;
  }

  #progress-bar {
    left: 25px !important;

    @include breakpoint(sm-up) {
      left: 92px !important;
    }
  }

  #legend-mobile {
    @include breakpoint(sm-up) {
      left: 64px !important;
    }
  }

  .leaflet-popup-content-wrapper {
    background-color: transparent !important;
    border-radius: 0 !important;
    padding: 0 !important;
    box-shadow: none !important;
  }

  .leaflet-popup-content {
    margin: 0 !important;
  }

  .leaflet-popup-tip {
    position: relative;
    background-color: var(--body-background) !important;
    z-index: 100;
    box-shadow: none !important;
  }

  .leaflet-popup-close-button {
    @extend .MapControlButtonOfGroup;
    z-index: 1100 !important;
    top: -5.5px !important;
    font-size: 26px !important;
    font-weight: 400;
    right: -7px !important;
    width: 40px !important;
    height: 42px !important;
    line-height: 1.3 !important;
  }

  .leaflet-popup-tip-container {
    height: 13px !important;
  }

  .leaflet-tooltip {
    padding: 0 !important;
    border: none !important;
  }

  .leaflet-control {
    border-radius: var(--border-radius-base);
  }

  .leaflet-control-container {
    display: block !important;

    .leaflet-top {
      z-index: 1001;
    }
  }

  #mobile-ovr-select {
    display: none !important;
  }

  .leaflet-control-container
    .leaflet-top.leaflet-left
    .leaflet-bar.leaflet-control {
    position: static;
  }

  .leaflet-bottom .leaflet-control {
    margin-bottom: 8px !important;
    user-select: none;
  }

  .leaflet-bottom {
    position: absolute;
    z-index: 1001;
  }

  /* player styles are hidden */
  #logo-wrapper {
    display: none;
  }

  #bottom {
    z-index: 500;
    position: fixed !important;
  }
}

/**
 *
 */

/**
 *
 */

/**
 *
 */
.MainMap #windy {
  #logo {
    top: -555px;
  }
  #calendar,
  #mobile-calendar {
    display: none;
  }
  #progress-bar {
    left: 25px !important;

    @include breakpoint(sm-up) {
      left: 92px !important;
    }
  }
  #legend-mobile {
    @include breakpoint(sm-up) {
      left: 64px !important;
    }
  }
  #mobile-ovr-select {
    display: none !important;
  }
  /* player styles are hidden */
  #logo-wrapper {
    display: none;
  }

  #bottom {
    z-index: 500;
    position: fixed !important;
  }
}

/**
 *
 */
.ActivityPlayer,
.HistoricalCatch,
.TrackPlayer,
.MainMap .VesselsMap__layout,
.MainMap #windy {
  height: 100%;
  width: 100%;
  margin: 0 auto;

  .leaflet-popup-content-wrapper {
    background-color: transparent !important;
    border-radius: 0 !important;
    padding: 0 !important;
    box-shadow: none !important;
  }

  .leaflet-popup-content {
    margin: 0 !important;
  }

  .leaflet-popup-tip {
    position: relative;
    background-color: var(--body-background) !important;
    z-index: 100;
    box-shadow: none !important;
  }

  .leaflet-popup-close-button {
    @extend .MapControlButtonOfGroup;
    z-index: 1100 !important;
    top: -5.5px !important;
    font-size: 26px !important;
    font-weight: 400;
    right: -7px !important;
    width: 40px !important;
    height: 42px !important;
    line-height: 1.3 !important;
  }

  .leaflet-popup-tip-container {
    height: 13px !important;
  }

  .leaflet-tooltip {
    padding: 0 !important;
    border: none !important;
  }

  .leaflet-control {
    border-radius: var(--border-radius-base);
  }
}

/**/
.leaflet-touch {
  .leaflet-bar {
    border: 0 !important;
  }
}

.leaflet-popup {
  width: 220px !important;
}

.leaflet-control-attribution {
  display: none !important;
}

.slick-track {
  min-width: 100%;
}

.leaflet-tooltip-top::before {
  border-top-color: var(--body-background) !important;
  z-index: 999;
}

// Make Seabed layer B&W
.leaflet-layer.SeabedLayer {
  img {
    filter: grayscale(1);
  }

  &--color img {
    filter: grayscale(0);
  }
}

// Make EEZ less vivid
.leaflet-layer.EEZLayer img {
  filter: grayscale(1);
}

// Fix: coming from Windy no ICES tiles are visible.
.leaflet-layer.ICESLayer .leaflet-tile-container {
  opacity: 0.5;
}

.TileLoader.ant-message-notice {
  .Body--mobile & {
    position: relative;
    top: calc(100vh / 3);
  }
}
