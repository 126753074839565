.VesselsMap .leaflet-container .leaflet-top.leaflet-right {
  height: 0;

  .Body--touch & {
    float: none;
    left: 0;
  }
}

.VesselsMap
  .leaflet-container
  .leaflet-top.leaflet-right
  .leaflet-control-coordinates {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.7);
  padding: 0 12px;
  margin: 0 69px 0 0 !important;
  color: $black;
  font-family: $font-inter;
  font-weight: 600;
  border-radius: var(--border-radius-base);
  min-width: 270px;

  .Body--touch & {
    max-width: 230px;
    min-width: unset;
    float: none !important;
    margin: 0 auto !important;
    border-radius: 0;
  }

  .Body--ios & {
    display: none;
  }

  &-message {
    font-size: 8px;
    color: grey;
  }

  &-block {
    font-size: 14px;
    min-width: 120px;
    user-select: none;

    .Body--touch & {
      min-width: auto;
      font-size: 11px;
      line-height: 12px;
    }

    b {
      font-weight: 600;
    }

    &:first-child {
      margin-right: 1rem;
    }
  }
}

/**
 *
 */
.ActivityPlayer
  .leaflet-container
  .leaflet-top.leaflet-right
  .leaflet-control-coordinates,
.HistoricalCatch
  .leaflet-container
  .leaflet-top.leaflet-right
  .leaflet-control-coordinates,
.TrackPlayer
  .leaflet-container
  .leaflet-top.leaflet-right
  .leaflet-control-coordinates,
.MainMap
  .leaflet-container
  .leaflet-top.leaflet-right
  .leaflet-control-coordinates {
  //display: flex;
  //flex-direction: row;
  //justify-content: space-between;
  background: rgba(255, 255, 255, 0.9);
  padding: 0 4px;
  margin: 8px 48px 0 0 !important;
  color: $black;
  //font-weight: 600;
  border-radius: var(--border-radius-base);
  min-width: 110px;

  //.Body--touch & {
  //  max-width: 230px;
  //  min-width: unset;
  //  float: none !important;
  //  margin: 0 auto !important;
  //  border-radius: 0;
  //}
  //
  //.Body--ios & {
  //  display: none;
  //}

  &-message {
    font-size: 8px;
    color: grey;
    font-family: $font-inter;
    line-height: 22px;
  }

  &-block {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    font-family: $font-inter;
    line-height: 18px;
    user-select: none;

    //.Body--touch & {
    //  min-width: auto;
    //  font-size: 11px;
    //  line-height: 12px;
    //}
    //
    //b {
    //  font-weight: 600;
    //}
    //
    //&:first-child {
    //  margin-right: 1rem;
    //}
  }
}
